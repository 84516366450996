import { ProviderService } from 'shared/models/providerService.model';

export default class HeaderService extends ProviderService {
  static readonly serviceName = 'headerService';
  public isCatalogOpened = ref(false);
  public isSearchButtonDropdownOpened = ref(false);

  public setCatalogOpened(isOpened: boolean) {
    this.isCatalogOpened.value = isOpened;
  }

  public setSearchButtonDropdownOpened(isOpened: boolean) {
    this.isSearchButtonDropdownOpened.value = isOpened;
  }
}
